import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngPTwoENFTGame = loadable(() => import("../components/PTwoENFTGame/english.js"));
const ArabicPTwoENFTGame = loadable(() => import("../components/PTwoENFTGame/arabic.js"));
const ChinesePTwoENFTGame = loadable(() => import("../components/PTwoENFTGame/chinese.js"));

const schema = {
  "@context": "http://schema.org/",
  "@graph": [
    {
      "@type": "product",
      "image": "https://softtik.com/images/nft-collection/invest-section-6.webp",
      "name": "Top Play To Earn NFT Game Development Company",
      "description": "Softtik is a leading play-to-earn NFT game development company that is reshaping the gaming industry with the help of its high-end solutions and pro team.",
      "brand": "Softtik Technologies",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.7",
        "reviewCount": "107"
      }
    },
    {
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "Can NFT be used for gaming?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p> Yes, NFTs can be used for gaming. The NFTs have provided actual ownership of the in-game purchases to the players. Players can even move one game's assets to another game or marketplace for sale.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How do I invest in NFT games?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>You can either invest in developing an NFT game for yourself with <b>Softtik Technologies</b> or play games to earn several rewards and tokens.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How do NFT games make profit?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Developers make money when gamers purchase initial necessary game assets. Players can play the game and complete different quests and challenges to earn rewards; NFTs or game tokens. Players can sell these collected assets to make money.</p>"
        }
      }
      ]
    }
  ]
}

export class PTwoENFTGame extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: "English"
    }
  };

  async componentDidMount() {
    let region = localStorage.getItem('region');
    if (region == 'ae') this.setState({ lang: "Arabic" });
    else if (region == 'cn') this.setState({ lang: "China" });
    else this.setState({ lang: "English" });
  };

  render() {
    let { lang } = this.state;
    return (
      <>
        <InnerLayout header='Main' fallback={<Loader />}>
          <Head
            type="Website"
            fallback={<Loader />}
            schemaMarkup={schema}
            path="/nft-game-development-services/"
            title="NFT Game Development Company - Softtik Technologies"
            thumbnail="https://softtik.com/images/nft-collection/invest-section-6.webp"
            description="Softtik is a leading play-to-earn NFT game development company that is reshaping the gaming industry with the help of its high-end solutions and pro team."
          />
          <main onWheel={this.onScroll}>
            <div id="wrapper">
              <div className={`collection-page game-development-page ${lang == "Arabic" && 'rtl'}`}>
                {lang == "Arabic"
                  ? <ArabicPTwoENFTGame />
                  : lang == "China"
                    ? <ChinesePTwoENFTGame />
                    : <EngPTwoENFTGame />
                }
              </div>
            </div>
          </main>
        </InnerLayout >
      </>
    )
  };
};

export default PTwoENFTGame;